var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.deadlinesAfter ? _c('div', {
    staticClass: "thank-you-box"
  }, [_c('div', {
    staticClass: "thank-wrap"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/anniversary_2023/thank/check.svg'),
      "alt": "check"
    }
  }), _c('form', {
    staticClass: "form"
  }, [_vm._m(0), _c('div', {
    staticClass: "form-main"
  }, [_c('h2', [_vm._v("好禮二選一")]), _c('div', {
    staticClass: "btn-thank-group"
  }, [_c('button', {
    attrs: {
      "type": "button",
      "disabled": _vm.fileName === 'childFile1'
    },
    on: {
      "click": function ($event) {
        return _vm.downloand(_vm.childFile2, 'childFile2');
      }
    }
  }, [_vm._v("下載 宇宙主題學習單")]), _c('button', {
    attrs: {
      "type": "button",
      "disabled": _vm.fileName === 'childFile2'
    },
    on: {
      "click": function ($event) {
        return _vm.downloand(_vm.childFile1, 'childFile1');
      }
    }
  }, [_vm._v("下載 兒童英文繪本有聲書")])])]), _c('button', {
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push({
          name: 'home'
        });
      }
    }
  }, [_vm._v("返回WUWOW Jr官網")])])])]) : _c('div', [_c('div', {
    staticClass: "thank-you-box col"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "text-center"
  }, [_c('img', {
    attrs: {
      "src": _vm.wuwowThankyouPng,
      "alt": "WUWOW 線上英語教學 感謝您"
    }
  })])], 1), _c('b-row', {
    staticClass: "mt-5"
  }, [_c('b-col', [_c('span', {
    staticClass: "thank-you-content"
  }, [_vm._v("謝謝您提供的訊息，我們已經收到您的資料！"), _c('br'), _vm._v("我們會盡快安排專員與您聯絡！")])])], 1), _c('b-row', {
    staticClass: "mt-5"
  }, [_c('b-col', {
    staticClass: "thank-button-group"
  }, [_vm.hasWritedPhone ? _c('b-button', {
    staticClass: "go-back-home-btn m-bottom-5",
    attrs: {
      "variant": "primary",
      "href": "https://wuwow-store.s3.ap-southeast-1.amazonaws.com/pictures/video/2023_jr_king_arthur_legend_the_sword_in_the_stone_voice_book",
      "target": "_blank"
    }
  }, [_vm._v("下載 兒童英文繪本有聲書")]) : _vm._e(), _vm.hasWritedPhone ? _c('b-button', {
    staticClass: "go-back-home-btn m-bottom-5",
    attrs: {
      "variant": "primary",
      "href": "https://wuwow-store.s3.ap-southeast-1.amazonaws.com/pictures/Fast+guide/202305_jr_cambridge_english_starters_words_book.pdf",
      "target": "download"
    }
  }, [_vm._v("下載 劍橋兒童英檢單字書")]) : _vm._e(), _c('b-button', {
    staticClass: "go-back-home-btn",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function ($event) {
        return _vm.backToHome();
      }
    }
  }, [_vm._v("返回WUWOW Jr官網")])], 1)], 1)], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "text-center"
  }, [_vm._v("謝謝您提供的訊息，我們已經收到您的資料！"), _c('br'), _vm._v("我們會盡快安排專員與您聯絡！")]);

}]

export { render, staticRenderFns }