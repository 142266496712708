<template lang="pug">
.thank-you-box(v-if="deadlinesAfter")
  .thank-wrap
    img(:src="require('@/assets/anniversary_2023/thank/check.svg')" alt="check")
    form.form
      p.text-center
        | 謝謝您提供的訊息，我們已經收到您的資料！
        br
        | 我們會盡快安排專員與您聯絡！
      .form-main
        h2 好禮二選一
        .btn-thank-group
          button(type="button" :disabled="fileName === 'childFile1'" @click="downloand(childFile2, 'childFile2')") 下載 宇宙主題學習單
          button(type="button" :disabled="fileName === 'childFile2'" @click="downloand(childFile1, 'childFile1')") 下載 兒童英文繪本有聲書
      button(type="button" @click="$router.push({name:'home'})") 返回WUWOW Jr官網
div(v-else)
  .thank-you-box.col
    b-row
      b-col.text-center
        img(:src="wuwowThankyouPng" alt="WUWOW 線上英語教學 感謝您")
    b-row.mt-5
      b-col
        span.thank-you-content
          | 謝謝您提供的訊息，我們已經收到您的資料！
          br
          | 我們會盡快安排專員與您聯絡！
    b-row.mt-5
      b-col.thank-button-group
        b-button.go-back-home-btn.m-bottom-5( v-if="hasWritedPhone" variant="primary" href="https://wuwow-store.s3.ap-southeast-1.amazonaws.com/pictures/video/2023_jr_king_arthur_legend_the_sword_in_the_stone_voice_book" target="_blank") 下載 兒童英文繪本有聲書
        b-button.go-back-home-btn.m-bottom-5( v-if="hasWritedPhone" variant="primary" href="https://wuwow-store.s3.ap-southeast-1.amazonaws.com/pictures/Fast+guide/202305_jr_cambridge_english_starters_words_book.pdf" target="download") 下載 劍橋兒童英檢單字書
        b-button.go-back-home-btn(@click="backToHome()" variant="outline-primary") 返回WUWOW Jr官網
</template>

<script>
import wuwowThankyouPng from '@/assets/wuwow_ty.png';
import moment from 'moment';
export default {
  name: 'ThankYouPage',

  data() {
    return {
      deadlines: '2024-04-15 00:00:00',
      deadlinesAfter: false,
      childFile1: 'https://wuwow-store.s3.ap-southeast-1.amazonaws.com/pictures/video/2023_jr_king_arthur_legend_the_sword_in_the_stone_voice_book',
      childFile2: 'https://wuwow-store.s3.ap-southeast-1.amazonaws.com/pictures/Fast+guide/Junior_Worksheet_Universe.pdf',
      fileName: '',
      wuwowThankyouPng: wuwowThankyouPng,
      head: null,
      setFreecoinsCvq: null,
      freecoins: null,
      hasWritedPhone: localStorage.getItem('hasWritedPhone') || '',
    };
  },

  created() {
    this.initLinePoint();
    this.deadlinesAfter = moment(new Date()).isBetween('2024-04-01 09:00:00', this.deadlines);
  },
  destroyed() {
    this.head.removeChild(this.setFreecoinsCvq);
    this.head.removeChild(this.freecoins);
  },

  methods: {
    backToHome(){
      this.$router.push({ path: '/' });
    },
    downloand(file, type) {
      this.fileName = type;
      const a = document.createElement('a');
      a.setAttribute('href', file);
      a.setAttribute('download', '');
      a.setAttribute('target', '_blank');
      a.click();
      a.remove();
    },
    initLinePoint() {
      // Line Point 前置作業
      this.head = document.querySelector('head');
      this.setFreecoinsCvq = document.createElement('script');
      this.setFreecoinsCvq.setAttribute('type', 'text/javascript');
      this.setFreecoinsCvq.innerHTML = `
        if(localStorage.getItem('setFreecoinsCvq') == "true") {
          let phone = localStorage.getItem('trialPhone');
          var freecoins_cvq = [
            {
              app: "FREECOINS_33485",
              domain: ".wuwowjr.tw",
              cv: [
                {
                  action: "REGISTRATION",
                  order: phone,
                  item: "trial2023Form",
                  t_price: "1",
                  quantity: "1",
                  memo: "JR官網試讀感謝頁"
                }
              ]
            }
          ];
        }`;
      this.freecoins = document.createElement('script');
      this.freecoins.setAttribute('src', 'https://freecoins.line-apps.com/lfc5.js');
      this.freecoins.setAttribute('async', '');
      this.head.appendChild(this.setFreecoinsCvq);
      this.head.appendChild(this.freecoins);
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin sm {
  @media (min-width: 475px) {
    @content;
  }
}

@mixin md {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin lg {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin xl {
  @media (min-width: 1240px) {
    @content;
  }
}

@mixin xxl {
  @media (min-width: 1680px) {
    @content;
  }
}

@mixin xxxl {
  @media (min-width: 1920px) {
    @content;
  }
}
.thank-you-box{
  height: 100vh;
  text-align: center;
  background-color: #F5F5F5;
  background-image: url(~@/assets/thank_you_bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  &.col {
    align-items: stretch;
    flex-direction: column;
  }
}
.thank-you-content{
  font-size: 18px;
  color: var( --text-light-color );
}
.thank-button-group {
  max-width: 322px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.m-bottom-5 {
  margin-bottom: 20px;
}
.go-back-home-btn{
  font-size: 16px;
  padding: 15px 0px;
}
.thank-wrap {
  padding: 0 16px;
  text-align: center;

  img {
    margin-bottom: 40px;
  }

  @include md {
    padding: 0;
  }
}

.form {
  /* background: rgba(255, 255, 255, 0.4);
  border: 1px solid #fff;
  border-radius: 20px; */
  text-align: center;
  width: 100%;
  padding: 16px;

  @include lg {
    padding: 40px;
    width: 569px;
  }

  p {
    font-size: 14px;
    line-height: 20px;
    padding: 40px 0;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 15px;
    margin-bottom: 40px;
    color: #505050;
  }

  h2 {
    font-weight: 700;
    font-size: 21px;
    line-height: 1;
    margin-bottom: 20px;
  }

  &-main {
    background: #E1F8FF;
    border-radius: 10px;
    padding: 20px;
    @media (min-width: 1024px) {
      margin: 0 -25px;
    }
  }

  >button {
    background: transparent;
    border: 1px solid #75CDEC;
    color: #75CDEC;
    border-radius: 5px;
    width: 322px;
    outline: none;
    margin-top: 20px;
    padding: 15px 0;
    transition: all 0.3s;

    &:hover {
      background: #75CDEC;
      color: white;
    }
  }
}

.btn-thank-group {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;

  button {
    flex-grow: 1;
    appearance: none;
    border: none;
    outline: none;
    background: #75CDEC;
    color: #fff;
    border-radius: 5px;
    padding: 15px 8px;
    @media (min-width: 1024px) {
      max-width: 50%;
    }

    &:hover {
      background: lighten(#75CDEC, 20%);
    }
    &:disabled {
      background: #EDECEA;
      color: #ADADAD;
    }
    &:hover:disabled {
      background: #EDECEA;
      color: #ADADAD;
    }
  }
}
</style>
